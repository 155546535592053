import migrateModifier from "@/lib/update/migrations/0.64.1/migrateModifier"
import migrateProfile from "@/lib/update/migrations/0.64.1/migrateProfile"
import migrateWeapon from "@/lib/update/migrations/0.64.1/migrateWeapon"

export default function migrate_0_64_1(profile) {
  // Profiles.
  const migratedProfile = migrateProfile(profile)

  // Unit & weapon abilities.
  migratedProfile.units.forEach((unit) => {
    // Unit abilities.
    if (unit.abilities?.length) {
      unit.abilities.forEach((unitAbility) => {
        migrateModifier(unitAbility)
      })
    }

    // Weapon abilities.
    for (const modelType of unit.modelTypes) {
      if (!modelType?.weapons) continue
      for (const weapon of modelType.weapons) {
        migrateWeapon(weapon)
        if (!weapon?.abilities) continue
        weapon.abilities.forEach((weaponAbility) => {
          migrateModifier(weaponAbility)
        })
      }
    }
  })

  // Mark as updated.
  migratedProfile.updateStatus.updated = true

  // Return migrated profile.
  return migratedProfile
}
