import weaponAbilitiesStringsToObjects from "@/lib/update/migrations/0.26.0/weaponAbilitiesStringsToObjects"

export default function migrate_0_26_0(profile) {
  // Profile weapons.
  if (profile.weapons?.length) {
    profile.weapons.forEach((weapon, weaponIndex) => {
      weaponAbilitiesStringsToObjects(profile, weapon)

      if (typeof weapon.abilityIdNext === "undefined") {
        // Weapon has yet to be given an abilityIdNext property.
        weapon.abilityIdNext = 1
        // Loop through any weapon abilities (again!).
        if (typeof weapon.abilities !== "undefined") {
          weapon.abilities.forEach((ability) => {
            // Set weapon ability ids, these are scoped to the weapon.
            ability.id = weapon.abilityIdNext
            weapon.abilityIdNext++
          })
        }
        profile.updateStatus.updated = true
      }

      // Weapon abilities.
      if (profile.weapons[weaponIndex].abilities?.length) {
        profile.weapons[weaponIndex].abilities.forEach((ability) => {
          // Update abilities that predate the use of ability.condition.eventType.
          if (typeof ability.condition !== "undefined") {
            if (typeof ability.condition.eventType === "undefined") {
              ability.condition.eventType = "specificRoll"
              profile.updateStatus.updated = true
            }
          }
        })
      }
    })
  }

  // Return migrated profile.
  return profile
}
