import migrateModifier from "@/lib/update/migrations/0.63.0/migrateModifier"

export default function migrate_0_63_0(profile) {
  // Profile abilities.
  if (profile.abilities?.length) {
    profile.abilities.forEach((profileAbility) => {
      migrateModifier(profileAbility)
    })
  }

  // Profile weapons.
  if (profile.weapons?.length) {
    profile.weapons.forEach((weapon, weaponIndex) => {
      // Weapon abilities.
      if (profile.weapons[weaponIndex].abilities?.length) {
        profile.weapons[weaponIndex].abilities.forEach((weaponAbility) => {
          migrateModifier(weaponAbility)
        })
      }
    })
  }

  // Mark as updated.
  profile.updateStatus.updated = true

  // Return migrated profile.
  return profile
}
