<script setup>
import SvgLogo from "@/assets/logotype-shield.svg"
import UcBtn from "@/components/UC/UcBtn.vue"
import { useGlobalStore } from "@/stores/global"

const store = useGlobalStore()

function onNavIconClick() {
  store.appNavDrawer = !store.appNavDrawer
}
</script>

<template>
  <v-app-bar absolute density="compact" flat class="mb-6">
    <v-toolbar-title>
      <router-link
        :to="{ name: 'Home' }"
        class="logo-link"
        aria-label="UnitCrunch"
      >
        <SvgLogo class="logo" alt="UnitCrunch" />
      </router-link>
    </v-toolbar-title>

    <v-spacer />

    <UcBtn
      :to="{ name: 'Home' }"
      exact
      variant="text"
      size="small"
      rounded="0"
      class="d-none d-sm-flex"
      data-cy="btn-app-bar-crunch"
    >
      Crunch
    </UcBtn>

    <UcBtn
      :to="{ name: 'ManageProfiles' }"
      variant="text"
      size="small"
      rounded="0"
      class="d-none d-sm-flex"
      data-cy="btn-app-bar-manage-profiles"
    >
      Manage profiles
    </UcBtn>

    <v-app-bar-nav-icon
      aria-label="menu"
      data-cy="btn-app-bar-menu"
      @click="onNavIconClick"
    />
  </v-app-bar>
</template>

<style lang="scss" scoped>
.logo-link {
  display: flex;
  align-items: center;
}

.logo {
  height: 18px;
  fill: white;

  &:hover {
    fill: rgb(var(--v-theme-primary));
  }
}
</style>
