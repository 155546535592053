import migrateModifier from "@/lib/update/migrations/0.65.0/migrateModifier"

export default function migrate_0_65_0(profile) {
  // Unit & weapon abilities.
  profile.units.forEach((unit) => {
    // Unit abilities.
    if (unit.abilities?.length) {
      unit.abilities.forEach((unitAbility, index) => {
        unit.abilities[index] = migrateModifier(unitAbility)
      })
    }

    // Weapon abilities.
    for (const modelType of unit.modelTypes) {
      if (!modelType?.weapons) continue
      for (const weapon of modelType.weapons) {
        if (!weapon?.abilities) continue
        weapon.abilities.forEach((weaponAbility, index) => {
          weapon.abilities[index] = migrateModifier(weaponAbility)
        })
      }
    }
  })

  // Mark as updated.
  profile.updateStatus.updated = true

  // Return migrated profile.
  return profile
}
