import modifierAlias from "@/lib/update/migrations/0.65.0/modifierAliases"

export default function migrateModifier(modifier) {
  // Regenerate all modifier aliases.
  const alias = modifierAlias(modifier)
  if (alias) {
    return {
      ...modifier,
      aliasActive: true,
      aliases: [alias],
    }
  }
  return modifier
}
