import { regexDiceNotation } from "@/lib/update/migrations/0.54.0/regex"

export default function sustainedHits(description) {
  // Technically this regex pattern will also match nonsense like
  // "Critical hit : 1D3 extra hits" (note the "1D3")
  // but we don't need to worry as the modifier description
  // shouldn't output that kind of nonsense in the first place.
  const regex = new RegExp(
    `^Critical hit : (${regexDiceNotation}) extra hit(s?)$`,
    "i"
  )
  const result = description.match(regex)
  if (result) {
    return {
      name: `Sustained hits ${result[1]}`,
      type: "reserved",
    }
  }
  return null
}
