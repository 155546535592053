/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from "./App.vue"
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
// Composables
import { createApp } from "vue"
import router from "./router"
// Plugins
import { registerPlugins } from "@/plugins"
// Pinia
import { createPinia } from "pinia"
import piniaPluginPersistedState from "pinia-plugin-persistedstate"
// Sentry
import * as Sentry from "@sentry/vue"

const pinia = createPinia()
pinia.use(piniaPluginPersistedState)

const app = createApp(App)

app.use(pinia)

app.use(Toast, {
  position: "bottom-right",
})

registerPlugins(app)

function getAppStateJson() {
  return localStorage.getItem("unitcrunch") ?? ""
}

// Only init Sentry if the site has been built for production.
// This includes all Netlify preview URLs (as it should).
if (import.meta.env.VITE_SENTRY_ENABLE === "true") {
  Sentry.init({
    app,
    beforeSend: (event, hint) => {
      hint.attachments = [
        { filename: "uc-app-state.json", data: getAppStateJson() },
      ]
      return event
    },
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    // NOTE: uBlock Origin will block Sentry's trace propagation if the URL is not whitelisted.
    tracePropagationTargets: [
      /^https:\/\/.*--unitcrunch\.netlify\.app/,
      /^https:\/\/www\.unitcrunch\.com/,
      // /^http:\/\/.localhost:3000/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
  Sentry.setTag("app.version", import.meta.env.PACKAGE_VERSION)
}

// Reload the page if there is a vite preload error
// (e.g. if a bundle is requested dynamically and fails).
// See: https://vitejs.dev/guide/build#load-error-handling
window.addEventListener("vite:preloadError", () => {
  window.location.reload()
})

app.use(router)
app.mount("#app")
