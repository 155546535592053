import migrateModifier from "@/lib/update/migrations/0.54.0/migrateModifier"
import migrateWeapon from "@/lib/update/migrations/0.54.0/migrateWeapon"

export default function migrate_0_54_0(profile) {
  if (profile?.profileTemplate === "daemon") {
    profile.save = profile.daemonSaveMelee
    profile.invuln = profile.daemonSaveRanged
  }

  // Profile abilities.
  if (profile.abilities?.length) {
    profile.abilities.forEach((profileAbility) => {
      migrateModifier(profileAbility)
    })
  }

  // Profile weapons.
  if (profile.weapons?.length) {
    profile.weapons.forEach((weapon, weaponIndex) => {
      migrateWeapon(profile, weapon)
      // Weapon abilities.
      if (profile.weapons[weaponIndex].abilities?.length) {
        profile.weapons[weaponIndex].abilities.forEach((weaponAbility) => {
          migrateModifier(weaponAbility)
        })
      }
    })
  }

  // Cleanup.
  delete profile.strength
  delete profile.bs
  delete profile.ws
  delete profile.profileTemplate
  delete profile.daemonSaveMelee
  delete profile.daemonSaveRanged
  // Loop through weapons again and delete weapon.subType (now that we've migrated Rapid fire & Dakka).
  if (profile.weapons?.length) {
    profile.weapons.forEach((weapon) => {
      delete weapon.subType
    })
  }

  // Mark as updated.
  profile.updateStatus.updated = true

  // Return migrated profile.
  return profile
}
