import conditionAttackCharacteristic from "@/lib/update/migrations/0.53.0/modifierDescription/conditions/attackCharacteristic"
import conditionDefenderCharacteristic from "@/lib/update/migrations/0.53.0/modifierDescription/conditions/defenderCharacteristic"
import conditionAttackStepRoll from "@/lib/update/migrations/0.53.0/modifierDescription/conditions/attackStepRoll"
import effectModifyAbsolute from "@/lib/update/migrations/0.53.0/modifierDescription/effects/modifyAbsolute"
import effectModifyRelative from "@/lib/update/migrations/0.53.0/modifierDescription/effects/modifyRelative"
import effectOverrideReqs from "@/lib/update/migrations/0.53.0/modifierDescription/effects/overrideReqs"
import effectReRoll from "@/lib/update/migrations/0.53.0/modifierDescription/effects/reRoll"

function arrayToString(array) {
  if (array.length) {
    let resultString = ""
    array.forEach(function (keyword, i) {
      resultString += keyword
      if (i < array.length - 1) {
        resultString += ", "
      }
    })
    return resultString
  } else {
    return ""
  }
}

export default function modifierDescription(modifier) {
  // Convenience constants.
  const { conditions, effect } = modifier

  const ellipsis = "[ … ]"
  const delimiter = ":"
  const sentenceObject = {
    preCondition: null,
    effect: "",
    postCondition: null,
    furtherConditions: [],
    keywordConditions: {
      excludes: null,
      only: null,
    },
  }
  const effectTextReplacements = []
  let description = ""

  // Effects

  // Modify (relative)
  if (effect.type === "modifyRelative") {
    sentenceObject.effect = effectModifyRelative({ effect })
  }

  // Modify (absolute)
  if (effect.type === "modifyAbsolute") {
    const modifyAbsolute = effectModifyAbsolute({
      effect,
      ellipsis,
    })
    sentenceObject.effect = modifyAbsolute.description
  }

  // Re-roll
  if (effect.type === "reRoll") {
    const reRoll = effectReRoll({
      effect,
      ellipsis,
    })
    sentenceObject.effect = reRoll.description
  }

  // Override requirements
  if (effect.type === "overrideReqs") {
    const overrideReqs = effectOverrideReqs({
      effect,
      ellipsis,
    })
    sentenceObject.effect = overrideReqs.description
    if (overrideReqs.postCondition) {
      sentenceObject.furtherConditions.push({
        example: "(irrespective of attack modifiers)",
        text: overrideReqs.postCondition,
        type: effect.type,
      })
    }
  }

  // Ignore wounds
  if (effect.type === "ignoreWounds") {
    if (effect.data.ignoreValue) {
      sentenceObject.effect = `${effect.data.ignoreValue}+++ ignore wounds`
    }
  }

  // Invulnerable save
  if (effect.type === "invulnSave") {
    if (effect.data.invulnValue) {
      sentenceObject.effect = `${effect.data.invulnValue}++ invuln save`
    }
  }

  // Special
  if (effect.type === "special") {
    if (effect.data.option) {
      sentenceObject.effect = effect.data.option.text
    }
  }

  // Disable ability
  if (effect.type === "disableAbility") {
    if (effect.data.option) {
      sentenceObject.effect = effect.data.option.text
    }
  }

  // Generate extras
  if (effect.type === "generateExtras") {
    if (effect.data.option && effect.data.extrasValue) {
      sentenceObject.effect = `${effect.data.extrasValue} `
      if (Number(effect.data.extrasValue) === 1) {
        // Trims the trailing "s" off if value is 1.
        sentenceObject.effect += effect.data.option.text.slice(0, -1)
      } else {
        sentenceObject.effect += effect.data.option.text
      }
    }
  }

  // Mortal wounds
  if (effect.type === "mortalWounds") {
    if (effect.data.mortalWoundsValue) {
      sentenceObject.effect = `${effect.data.mortalWoundsValue} mortal wound`
      // Pluralise if value is >1 or uses dice notation.
      if (
        Number(effect.data.mortalWoundsValue) > 1 ||
        isNaN(Number(effect.data.mortalWoundsValue))
      ) {
        sentenceObject.effect += "s"
      }
      if (effect.data.cap) {
        sentenceObject.effect += ` (max ${effect.data.cap})`
      }
      if (effect.data.attackSequenceEnds) {
        sentenceObject.effect += " (attack sequence ends)"
      }
    }
  }

  // Conditions

  conditions.forEach((condition) => {
    // Pre-conditions

    // Attack characteristic
    if (condition.type === "attackCharacteristic") {
      sentenceObject.preCondition = conditionAttackCharacteristic({
        condition,
        ellipsis,
      })
    }

    // Defender characteristic
    if (condition.type === "defenderCharacteristic") {
      sentenceObject.preCondition = conditionDefenderCharacteristic({
        condition,
        ellipsis,
      })
    }

    // Pre/post-conditions

    // Attack step roll
    if (condition.type === "attackStepRoll") {
      const attackStepRollString = conditionAttackStepRoll({
        condition,
        ellipsis,
      })
      // Add to preCondition or postCondition depending on effect type.
      if (effect.type === "overrideReqs" && effect.data?.type === "only") {
        sentenceObject.postCondition =
          `on a ${attackStepRollString}`.toLowerCase()
      } else {
        sentenceObject.preCondition = attackStepRollString
      }
    }

    // Further conditions

    // Attack type.
    if (condition.type === "attackType" && condition.data.attackType) {
      sentenceObject.furtherConditions.push({
        example: "(melee only)",
        text: `(${condition.data.attackType.toLowerCase()} only)`,
        type: condition.type,
      })
    }

    // Profile role.
    if (condition.type === "profileRole" && condition.data.profileRole) {
      sentenceObject.furtherConditions.push({
        example: "(if attacker)",
        text: `(if ${condition.data.profileRole.toLowerCase()})`,
        type: condition.type,
      })
    }

    // Range
    if (condition.type === "range" && condition.data.range) {
      sentenceObject.furtherConditions.push({
        example: "(within half range)",
        text: `(${condition.data.range.text.toLowerCase()})`,
        type: condition.type,
      })
    }

    // Keyword conditions

    // Keywords (excludes)
    if (condition.type === "keywordsExcludes") {
      if (condition.data.keywords.length) {
        const textKeywordsExcludes = `excludes ${arrayToString(
          condition.data.keywords
        )}`
        sentenceObject.keywordConditions.excludes = {
          example: "excludes MONSTER, VEHICLE",
          text: textKeywordsExcludes,
        }
      }
    }

    // Keywords (only)
    if (condition.type === "keywordsOnly") {
      if (condition.data.keywords.length) {
        const textKeywordsOnly = `${arrayToString(
          condition.data.keywords
        )} only`
        sentenceObject.keywordConditions.only = {
          example: "MONSTER, VEHICLE only",
          text: textKeywordsOnly,
        }
      }
    }

    // Conditions that modify the effect phrasing.
    if (condition.type === "woundType") {
      if (condition.data.woundType.value === "mortalWounds") {
        effectTextReplacements.push({
          targetString: "ignore wounds",
          replacementString: "ignore mortal wounds",
        })
      }
    }
  })

  // Start building the description string.

  // Pre-condition.
  if (sentenceObject.preCondition) {
    description = `${sentenceObject.preCondition} ${delimiter} `
  }

  // Effect.

  // Check for possible substring replacements.
  if (effectTextReplacements.length) {
    effectTextReplacements.forEach((item) => {
      // Does effect include target string?
      if (sentenceObject.effect.includes(item.targetString)) {
        // Replace instances of target string with replacement string.
        sentenceObject.effect = sentenceObject.effect.replaceAll(
          item.targetString,
          item.replacementString
        )
      }
    })
  }
  // Append effect to description.
  if (!modifier.effect.type) {
    description += ellipsis
  }
  description += sentenceObject.effect

  // Post condition.
  if (sentenceObject.postCondition) {
    description += ` ${sentenceObject.postCondition}`
  }

  // Make entire description lowercase & trim all preceding & trailing whitespace.
  description = description.toLowerCase().trim()

  // Correct silly nonsense.
  const nonsenseReplacements = [
    {
      targetString: "only hit on a hit roll of",
      replacementString: "only hit on a",
    },
    {
      targetString: "only wound on a wound roll of",
      replacementString: "only wound on a",
    },
  ]
  nonsenseReplacements.forEach((item) => {
    // Does description include target string?
    if (description.includes(item.targetString)) {
      // Replace instances of target string with replacement string.
      description = description.replaceAll(
        item.targetString,
        item.replacementString
      )
    }
  })

  // Append further conditions.
  for (const condition of sentenceObject.furtherConditions) {
    // Avoid adding overly verbose profileRole condition to certain effects.
    if (
      condition.type === "profileRole" &&
      ["ignoreWounds", "invulnSave"].includes(effect.type)
    ) {
      continue
    }
    // Append condition text.
    description += ` ${condition.text}`
  }

  // Append keyword conditions (these are always last).
  if (
    sentenceObject.keywordConditions.excludes ||
    sentenceObject.keywordConditions.only
  ) {
    // Convenience constants.
    const keywordsExcludes = sentenceObject.keywordConditions.excludes
    const keywordsOnly = sentenceObject.keywordConditions.only
    if (keywordsExcludes && keywordsOnly) {
      description += ` (${keywordsOnly.text} - ${keywordsExcludes.text})`
    }
    if (!keywordsExcludes && keywordsOnly) {
      description += ` (${keywordsOnly.text})`
    }
    if (keywordsExcludes && !keywordsOnly) {
      description += ` (${keywordsExcludes.text})`
    }
  }

  // Formatting.

  // Replace any double spaces with single spaces.
  description = description.replaceAll("  ", " ")
  // Capitalise certain substrings.
  description = description.replaceAll("ap", "AP")
  description = description.replace("bs", "BS")
  description = description.replace("d3", "D3")
  description = description.replace("d6", "D6")
  description = description.replace("ws", "WS")
  // Ensure first character of description is capitalised.
  description = description.charAt(0).toUpperCase() + description.slice(1)

  if (description === "") {
    description = ellipsis
  }

  return description
}
