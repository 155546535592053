import { cloneDeep, merge } from "lodash-es"
// Effect & condition defaults will change after this migration ships.
// Using them directly would likely break future runs of this migration as the defaults change over time.
// The defaults imported into this file are copies of the actual defaults.
// These copies are not intended to change after the migration ships.
import { defaultModifierConditions } from "@/lib/update/migrations/0.53.0/migrateModifier/modifierConditions_0_53_0"
import { defaultModifierEffects } from "@/lib/update/migrations/0.53.0/migrateModifier/modifierEffects_0_53_0"
import modifierDescription from "@/lib/update/migrations/0.53.0/modifierDescription"

function cloneDefaultCondition(condition, conditionType) {
  // This came up in an update error bug report:
  if (conditionType === "keywordsExcluded") {
    conditionType = "keywordsExcludes"
  }
  const defaultCondition = defaultModifierConditions.find(
    (item) => item.type === conditionType
  )
  if (typeof defaultCondition === "undefined") {
    // If we can't find a default condition then this condition
    // is likely from teh future and doesn't need migrating.
    return {
      // Note that we omit the condition description.
      data: condition.data,
      preselected: condition.preselected,
      text: condition.text,
      type: condition.type,
    }
  }
  return cloneDeep({
    // Note that we omit the condition description.
    data: defaultCondition.data,
    preselected: defaultCondition.preselected,
    text: defaultCondition.text,
    type: defaultCondition.type,
  })
}

function cloneDefaultEffect(effectType) {
  // Finds a default effect by its type and returns a clone of the default effect.
  const defaultEffect = defaultModifierEffects.find(
    (item) => item.type === effectType
  )
  return cloneDeep({
    // Note that we omit the effect description & scope.
    data: defaultEffect.data,
    text: defaultEffect.text,
    type: defaultEffect.type,
  })
}

function migrateCondition(ability, condition) {
  let newCondition = null
  if (condition.type === "rollSpecific") {
    // Migrate "rollSpecific" to "attackStepRoll".
    // Merge with attackStepRoll default and then tweak.
    newCondition = merge(
      cloneDefaultCondition(condition, "attackStepRoll"),
      condition
    )
    newCondition.type = "attackStepRoll"
    newCondition.data.rollReq = "specificRoll"
    // This ability will be of type "overrideReqs"
    // (it's the only place the "rollSpecific" condition appears in legacy modifiers).
    // Set the attackStep based on the "outcome" property.
    newCondition.data.attackStep = "hitRoll"
    if (ability.override.outcome === "wound") {
      newCondition.data.attackStep = "woundRoll"
    }
  } else {
    if (condition.type === "rollAttackStep") {
      condition.type = "attackStepRoll"
    }
    // Most legacy conditions can simply be merged with
    // the equivalent new default and that's all that's needed.
    newCondition = merge(
      cloneDefaultCondition(condition, condition.type),
      condition
    )
  }
  // Push migrated condition to placeholder array.
  ability.new.conditions.push(newCondition)
}

function migrateEffect(ability) {
  let newEffect = null

  if (ability.type === "conditionalEffect") {
    if (ability.effect.type.value === "autoWounds") {
      // Base new effect on the "overrideReqs" default object.
      newEffect = cloneDefaultEffect("overrideReqs")
      // Set properties statically.
      newEffect.data.outcome = "wound"
      newEffect.data.type = "always"
      if (typeof ability.effect.treatWoundAsUnmodified6 !== "undefined") {
        newEffect.data.treatWoundAsUnmodified6 =
          ability.effect.treatWoundAsUnmodified6
      }
    }

    if (ability.effect.type.value === "excessDamageNotLost") {
      // Base new effect on the "special" default object.
      newEffect = cloneDefaultEffect("special")
      // Set properties statically.
      newEffect.data.option = {
        text: "Excess damage not lost",
        value: "excessDamageNotLost",
      }
    }

    if (ability.effect.type.value.startsWith("extra")) {
      // Base new effect on the "generateExtras" default object.
      newEffect = cloneDefaultEffect("generateExtras")
      newEffect.data.extrasValue = ability.effect.value
      newEffect.data.option = {
        text: null,
        value: null,
      }
      switch (ability.effect.type.value) {
        case "extraAttacks":
          newEffect.data.option.text = "Extra attacks"
          newEffect.data.option.value = "extraAttacks"
          break
        case "extraHits":
          newEffect.data.option.text = "Extra hits"
          newEffect.data.option.value = "extraHits"
          break
        case "extraWounds":
          newEffect.data.option.text = "Extra wounds"
          newEffect.data.option.value = "extraWounds"
          break
      }
    }

    if (ability.effect.type.value === "improveAp") {
      // Base new effect on the "modifyRelative" default object.
      newEffect = cloneDefaultEffect("modifyRelative")
      // Set most properties statically with the odd dynamic value taken from the legacy ability.
      newEffect.data.application = {
        // Note that we omit the optionSets property - no longer needed.
        text: "AP",
        value: "ap",
      }
      // modifyRelative doesn't allow this value to be set beyond +/- 3,
      // so we need to cap this value at 3.
      const abilityEffectValue = Math.min(Number(ability.effect.value), 3)
      newEffect.data.option = {
        text: `Improve by ${String(abilityEffectValue)}`,
        value: abilityEffectValue,
        operator: "subtract",
      }
    }

    if (ability.effect.type.value === "improveDamage") {
      // Base new effect on the "modifyRelative" default object.
      newEffect = cloneDefaultEffect("modifyRelative")
      // Set most properties statically with the odd dynamic value taken from the legacy ability.
      newEffect.data.application = {
        // Note that we omit the optionSets property - no longer needed.
        text: "Damage",
        value: "damage",
      }
      newEffect.data.option = {
        text: `+${String(ability.effect.value)}`,
        value: ability.effect.value,
        operator: "add",
      }
    }

    if (ability.effect.type.value === "mortalWounds") {
      // Base new effect on the "mortalWounds" default object.
      newEffect = cloneDefaultEffect("mortalWounds")
      // Set most properties statically with the odd dynamic value taken from the legacy ability.
      newEffect.data.mortalWoundsValue = ability.effect.value
      if (typeof ability.effect.cap !== "undefined") {
        newEffect.data.cap = ability.effect.cap
      }
      if (typeof ability.effect.attackSequenceEnds !== "undefined") {
        newEffect.data.attackSequenceEnds = ability.effect.attackSequenceEnds
      }
    }

    if (ability.effect.type.value === "improveArmourSave") {
      // Base new effect on the "modifyRelative" default object.
      newEffect = cloneDefaultEffect("modifyRelative")
      // Set most properties statically with the odd dynamic value taken from the legacy ability.
      newEffect.data.application = {
        // Note that we omit the optionSets property - no longer needed.
        text: "Armour save roll",
        value: "armourSaveRoll",
      }
      newEffect.data.option = {
        text: `+${String(ability.effect.value)}`,
        value: ability.effect.value,
        operator: "add",
      }
    }

    if (ability.effect.type.value === "reduceDamage") {
      // Base new effect on the "modifyRelative" default object.
      newEffect = cloneDefaultEffect("modifyRelative")
      // Set most properties statically with the odd dynamic value taken from the legacy ability.
      newEffect.data.application = {
        // Note that we omit the optionSets property - no longer needed.
        text: "Damage",
        value: "damage",
      }
      newEffect.data.option = {
        text: `-${String(ability.effect.value)}`,
        value: 0 - ability.effect.value,
        operator: "subtract",
      }
    }

    if (ability.effect.type.value === "setAp") {
      // Base new effect on the "modifyAbsolute" default object.
      newEffect = cloneDefaultEffect("modifyAbsolute")
      // Set most properties statically with the odd dynamic value taken from the legacy ability.
      newEffect.data.application = {
        text: "AP",
        value: "ap",
      }
      newEffect.data.absoluteValue = ability.effect.value
    }

    if (ability.effect.type.value === "setDamage") {
      // Base new effect on the "modifyAbsolute" default object.
      newEffect = cloneDefaultEffect("modifyAbsolute")
      // Set most properties statically with the odd dynamic value taken from the legacy ability.
      newEffect.data.application = {
        text: "Damage",
        value: "damage",
      }
      newEffect.data.absoluteValue = ability.effect.value
    }
  }

  if (ability.type === "ignoreWounds") {
    // Base new effect on the relevant default object.
    newEffect = cloneDefaultEffect(ability.type)
    // Set most properties statically with the odd dynamic value taken from the legacy ability.
    newEffect.data.ignoreValue = ability.effect.value
  }

  if (ability.type === "invulnSave") {
    // Base new effect on the relevant default object.
    newEffect = cloneDefaultEffect(ability.type)
    // Set most properties statically with the odd dynamic value taken from the legacy ability.
    newEffect.data.invulnValue = ability.effect.value
  }

  if (ability.type === "modifyAbsolute") {
    // Base new effect on the relevant default object.
    newEffect = cloneDefaultEffect(ability.type)
    // Migrate from legacy ability.
    newEffect.data.application = {
      text: ability.application.text,
      value: ability.application.value,
    }
    newEffect.data.absoluteValue = ability.valueAbsolute
  }

  if (ability.type === "modifyRelative") {
    // Base new effect on the relevant default object.
    newEffect = cloneDefaultEffect(ability.type)
    // Migrate from legacy ability.
    newEffect.data.application = {
      // Note that we omit the optionSets property - no longer needed.
      text: ability.application.text,
      value: ability.application.value,
    }
    newEffect.data.option = ability.option
  }

  if (ability.type === "overrideReqs") {
    // Base new effect on the relevant default object.
    newEffect = cloneDefaultEffect(ability.type)
    // Migrate from legacy ability.
    newEffect.data.outcome = ability.override.outcome
    if (typeof ability.override.irrespective !== "undefined") {
      newEffect.data.irrespective = ability.override.irrespective
    }
    if (["always", "alwaysConditional"].includes(ability.override.type)) {
      newEffect.data.type = "always"
    }
    if (ability.override.type === "onlyConditional") {
      newEffect.data.type = "only"
    }
  }

  if (ability.type === "reRoll") {
    // Base new effect on the relevant default object.
    newEffect = cloneDefaultEffect(ability.type)
    // Migrate from legacy ability.
    newEffect.data.application = {
      text: ability.application.text,
      value: ability.application.value,
    }
    // TODO: Test this. I'm hoping it leaves the defaults alone if `ability.reRoll` doesn't have those properties.
    newEffect.data = merge(newEffect.data, ability.reRoll)
  }

  if (ability.type === "special") {
    if (ability.option.value === "blast") {
      // Base new effect on the relevant default object.
      newEffect = cloneDefaultEffect("special")
      // Migrate from legacy ability.
      newEffect.data.option = ability.option
    }

    if (ability.option.value.startsWith("deny")) {
      // Base new effect on the relevant default object.
      newEffect = cloneDefaultEffect("disableAbility")
      // Migrate from legacy ability.
      newEffect.data.option = {
        text: ability.option.text.replace("Deny", "Disable"),
        value: ability.option.value.replace("deny", "disable"),
      }
    }
  }

  // Set placeholder effect to migrated new effect.
  ability.new.effect = newEffect
}

function updateToNewModifierStructure(ability) {
  // Debug migrations as they're applied.
  // console.log(ability)

  // Create temp placeholders for new effect / conditions.
  ability.new = {
    conditions: [],
    effect: null,
  }

  // Migrate conditions to new.conditions array.
  if (ability.conditions?.length) {
    ability.conditions.forEach((condition) => {
      migrateCondition(ability, condition)
    })
  }

  // Migrate effect to new.effect object.
  migrateEffect(ability)

  // Use populated placeholders to replace legacy effect & conditions.
  ability.conditions = ability.new.conditions
  if (ability.new.effect !== null) {
    // Only replace ability.effect if new migrated version is not null.
    // THis prevents errors when re-running the migration on already successfully migrated abilities.
    ability.effect = ability.new.effect
  }

  // Delete all surplus properties.
  const propertiesWhitelist = [
    "conditions",
    "description",
    "effect",
    "id",
    "scope",
    "updated",
  ]
  const abilityKeys = Object.keys(ability)
  for (const key of abilityKeys) {
    // Skip if this key is on the whitelist.
    if (propertiesWhitelist.includes(key)) {
      continue
    }
    // Otherwise delete the property that has this key.
    delete ability[key]
  }

  /*
   * Try to update the ability description.
   *
   * This is wrapped in a try/catch as we're knowingly using a central function
   * that's not isolated to just this migration. Such a function may update in the future
   * in way that's no longer compatible with this migration and so could throw an error.
   *
   * I'm not copying a snapshot of modifierDescription() to this migration
   * as it's actually a fair few ES modules and ability descriptions aren't exactly critical.
   *
   * The fallback is to just use the legacy ability description. Such an ability would receive
   * an updated description if it is ever edited & saved.
   * */

  try {
    ability.description = modifierDescription(ability)
  } catch (error) {
    console.error(
      "Error updating migrated ability description. Will leave legacy description in place.",
      error
    )
  }

  // Return the updated ability object.
  return ability
}

export { updateToNewModifierStructure }
