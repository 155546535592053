import {
  updateKeywords,
  updateRollForEffect,
} from "@/lib/update/migrations/0.41.0/updateModifier"

function updateAttackType(ability) {
  // Migrate ability attackType to use new conditions property.
  if (typeof ability.attackType === "undefined") {
    return
  }
  if (ability.attackType) {
    if (typeof ability.conditions === "undefined") {
      ability.conditions = []
    }
    ability.conditions.push({
      type: "attackType",
      data: {
        attackType: ability.attackType,
      },
    })
  }
  delete ability.attackType
}

function updateModify(ability) {
  if (ability.type !== "modify") {
    return
  }
  ability.type = "modifyRelative"
}

function updateProfileRole(ability) {
  // Migrate ability profileRole to use new conditions property.
  if (typeof ability.profileRole === "undefined") {
    return
  }
  if (ability.profileRole) {
    if (typeof ability.conditions === "undefined") {
      ability.conditions = []
    }
    ability.conditions.push({
      type: "profileRole",
      data: {
        profileRole: ability.profileRole,
      },
    })
  }
  delete ability.profileRole
}

export default function updateProfileAbility(ability) {
  updateAttackType(ability)
  updateKeywords(ability)
  updateModify(ability)
  updateProfileRole(ability)
  updateRollForEffect(ability)
  delete ability.condition
}
