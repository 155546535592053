import modifierDescription from "@/lib/update/migrations/0.54.0/modifierDescription"
import modifierAliases from "@/lib/update/migrations/0.54.0/modifierAliases"

export default function migrateModifier(ability) {
  // Update modifyRelative effects to use new effect.data shape.
  if (
    ability.effect.type === "modifyRelative" &&
    typeof ability.effect.data.option !== "undefined" &&
    typeof ability.effect.data.operator === "undefined"
  ) {
    const option = ability.effect.data.option
    const data = ability.effect.data

    // Fix any inconsistencies in the option object first.
    if (option.text.startsWith("Improve")) {
      option.operator = "improve"
    }
    if (option.text.startsWith("Degrade")) {
      option.operator = "degrade"
    }

    // Convert negative number to positive number if needed.
    if (option.value < 0) {
      option.value = Math.abs(option.value)
    }

    // Init new properties to cover most cases.
    data.operator = {
      text: `${option.operator} by`,
      value: option.operator,
    }
    data.relativeValue = option.value

    // Different pattern for operator.text if operator is "add" or "subtract".
    if (["add", "subtract"].includes(option.operator)) {
      data.operator.text = option.operator
    }

    // Ensure first character of operator.text is capitalised.
    data.operator.text =
      data.operator.text.charAt(0).toUpperCase() + data.operator.text.slice(1)

    // Delete legacy property.
    delete ability.effect.data.option
  }

  // Update "ignoreWounds" effects to be "feelNoPain" effects.
  if (
    ability.effect.type === "ignoreWounds" &&
    typeof ability.effect.data.value === "undefined"
  ) {
    ability.effect.type = "feelNoPain"
    if (typeof ability.effect.text !== "undefined") {
      ability.effect.text = "Feel no pain"
    }
    ability.effect.data.value = ability.effect.data.ignoreValue
    delete ability.effect.data.ignoreValue
  }

  // Update "overrideReqs" effects to use "crit" rather than "treatWoundAsUnmodified6".
  if (ability.effect.type === "overrideReqs") {
    if (typeof ability.effect.data.treatWoundAsUnmodified6 !== "undefined") {
      ability.effect.data.crit = ability.effect.data.treatWoundAsUnmodified6
      delete ability.effect.data.treatWoundAsUnmodified6
    }
  }

  // Ensure mortalWounds effects have a matchWeaponDamage property.
  if (
    ability.effect.type === "mortalWounds" &&
    typeof ability.effect.data.matchWeaponDamage === "undefined"
  ) {
    ability.effect.data.matchWeaponDamage = false
  }

  // Regenerate ability description.
  ability.description = modifierDescription(ability)

  // Init aliases array.
  ability.aliases = []

  // Check for reserved aliases.
  const reservedAlias = modifierAliases(ability)

  // Add reserved alias if a match is found.
  if (reservedAlias) {
    ability.aliases.push(reservedAlias)
  }

  // Init aliasActive.
  ability.aliasActive = true

  return ability
}
