import modifierDescription from "@/lib/update/migrations/0.61.0/modifierDescription/index.js"

export default function migrateModifier(modifier) {
  // Update description for modifiers that use the "overrideReqs" effect.
  if (modifier?.effect.type === "overrideReqs") {
    // Regenerate modifier description.
    modifier.description = modifierDescription(modifier)
  }

  return modifier
}
