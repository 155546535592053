<script setup>
import SvgLogo from "@/assets/logo-shield-node-crest-dice.svg"
import { useGlobalStore } from "@/stores/global"

const envDev = import.meta.env.DEV
const envPackageVersion = import.meta.env.PACKAGE_VERSION
const store = useGlobalStore()
</script>

<template>
  <v-navigation-drawer
    v-model="store.appNavDrawer"
    temporary
    location="right"
    :scrim="true"
    floating
    class="bg-grey-darken-4"
  >
    <v-list>
      <v-list-item :to="{ name: 'Home' }" exact>
        <v-list-item-title>Crunch</v-list-item-title>
      </v-list-item>

      <v-list-item :to="{ name: 'ManageProfiles' }">
        <v-list-item-title>Manage profiles</v-list-item-title>
      </v-list-item>

      <v-list-item :to="{ name: 'About' }">
        <v-list-item-title>About</v-list-item-title>
      </v-list-item>

      <v-list-item :to="{ name: 'SupportUnitCrunch' }">
        <v-list-item-title>Support UnitCrunch</v-list-item-title>
      </v-list-item>

      <v-list-item :to="{ name: 'Faq' }">
        <v-list-item-title>FAQ</v-list-item-title>
      </v-list-item>

      <v-list-item :to="{ name: 'Changelog' }">
        <v-list-item-title>Changelog</v-list-item-title>
      </v-list-item>

      <v-list-item :to="{ name: 'Feedback' }">
        <v-list-item-title>Feedback</v-list-item-title>
      </v-list-item>

      <v-list-item :to="{ name: 'MathHammer' }">
        <v-list-item-title>What is MathHammer?</v-list-item-title>
      </v-list-item>

      <v-list-item :to="{ name: 'PrivacyPolicy' }">
        <v-list-item-title>Privacy policy</v-list-item-title>
      </v-list-item>
    </v-list>

    <template v-if="envDev">
      <v-divider />

      <v-list>
        <v-list-subheader>Dev</v-list-subheader>
        <v-list-item
          :to="{ name: 'ModifierSandbox', query: { scope: 'weapon' } }"
        >
          <v-list-item-title>Modifier sandbox</v-list-item-title>
        </v-list-item>

        <v-list-item :to="{ name: 'DevMigratePresetModifiers' }">
          <v-list-item-title>Migrate preset modifiers</v-list-item-title>
        </v-list-item>

        <v-list-item :to="{ name: 'DevMigratePresetProfiles' }">
          <v-list-item-title>Migrate preset profiles</v-list-item-title>
        </v-list-item>
      </v-list>
    </template>

    <v-divider />

    <v-list>
      <v-list-subheader>Social</v-list-subheader>
      <v-list-item
        href="https://www.reddit.com/r/UnitCrunch/"
        target="_blank"
        rel="noopener"
      >
        r/UnitCrunch
      </v-list-item>
      <!--      <v-list-item-->
      <!--        href="https://warhammer.social/web/@unitcrunch"-->
      <!--        target="_blank"-->
      <!--        rel="me"-->
      <!--        >Mastodon</v-list-item-->
      <!--      >-->
    </v-list>

    <v-divider />

    <v-list disabled>
      <v-list-item>
        <v-list-item-title class="text-caption"
          >Version: {{ envPackageVersion }}
        </v-list-item-title>
      </v-list-item>
    </v-list>

    <v-divider />

    <div class="logo-wrapper">
      <SvgLogo class="logo" />
    </div>
  </v-navigation-drawer>
</template>

<style lang="scss" scoped>
.logo-wrapper {
  display: flex;
  justify-content: center;
  padding: 32px 16px;
}

.logo {
  width: 50%;
  max-width: 100px;
}
</style>
