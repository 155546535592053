import {
  updateKeywords,
  updateRollForEffect,
} from "@/lib/update/migrations/0.41.0/updateModifier"
function updateOverrideReqs(ability) {
  if (
    ability.type !== "overrideReqs" ||
    typeof ability.condition === "undefined"
  ) {
    return
  }
  if (ability.condition) {
    if (typeof ability.conditions === "undefined") {
      ability.conditions = []
    }
    ability.conditions.push({
      type: "rollSpecific",
      data: {
        rollState: ability.condition.rollState,
        rollTarget: ability.condition.rollThreshold,
        rollValue: ability.condition.rollValue,
      },
    })
  }
}

function updateModify(ability) {
  if (ability.type !== "modify") {
    return
  }
  ability.type = "modifyRelative"
}

function updateSetEffect(ability) {
  if (ability.type !== "setEffect") {
    return
  }
  ability.type = "modifyAbsolute"
  ability.valueAbsolute = ability.effect.value
  // At this time we know all existing setEffect modifiers were for damage.
  ability.application = {
    text: "Damage",
    value: "damage",
  }
  // Should already be in place but belt & braces:
  ability.operator = "replace"
  delete ability.effect
}

export default function updateWeaponAbility(ability) {
  updateKeywords(ability)
  updateModify(ability)
  updateOverrideReqs(ability)
  updateRollForEffect(ability)
  updateSetEffect(ability)
  delete ability.condition
}
