/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import "@mdi/font/css/materialdesignicons.css"
import "vuetify/styles"
import { md2 } from "vuetify/blueprints"

// Composables
import { createVuetify } from "vuetify"

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  blueprint: md2,
  defaults: {
    VBtn: {
      color: "grey-darken-3",
    },
    VCardActions: {
      VBtn: {
        variant: "elevated",
      },
    },
    VCheckbox: {
      color: "primary",
    },
    VChipGroup: {
      color: "primary",
    },
    VExpansionPanel: {
      rounded: 0,
    },
    // VExpansionPanelTitle: {
    //   color: "grey-darken-4",
    // },
  },
  theme: {
    defaultTheme: "dark",

    // themes: {
    //   light: {
    //     colors: {
    //       primary: '#1867C0',
    //       secondary: '#5CBBF6',
    //     },
    //   },
    // },
  },
})
