import migrateProfile from "@/lib/update/migrations/0.62.7/migrateProfile"

export default function migrate_0_62_7(profile) {
  // Profiles.
  migrateProfile(profile)

  // Mark as updated.
  profile.updateStatus.updated = true

  // Return migrated profile.
  return profile
}
