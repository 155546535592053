import { regexDiceNotation } from "@/lib/update/migrations/0.56.0/regex"

export default [
  // Anti
  (description) => {
    const regex =
      /^Wound roll of ([2-5])\+ \(unmodified\) : critical wound \(([A-Z]+) only\)( \(if attacker\))?$/
    const match = description.match(regex)
    return match ? `Anti-${match[2]} ${match[1]}+` : null
  },
  // Blast
  (description) => {
    const regex = /^Blast( \(if attacker\))?$/
    return description.match(regex) ? "Blast" : null
  },
  // Conversion
  (description) => {
    const regex =
      /^Hit roll of 4\+ \(unmodified\) : critical hit \(over half range\)$/
    return description.match(regex) ? "Conversion" : null
  },
  // Devastating wounds
  (description) => {
    const regex =
      /^Critical wound : mortal wounds equal to weapon damage \(attack sequence ends\)( \(if attacker\))?$/
    return description.match(regex) ? "Devastating wounds" : null
  },
  // Heavy
  (description) => {
    const regex =
      /^\+1 to hit roll( \(if attacker\))? \(if attacker remained stationary\)$/
    return description.match(regex) ? "Heavy" : null
  },
  // Ignores cover
  (description) => {
    const regex = /^Ignore cover( \(if attacker\))?$/
    return description.match(regex) ? "Ignores cover" : null
  },
  // Lance
  (description) => {
    const regex = /^\+1 to wound roll \(if attacker charged\)$/
    return description.match(regex) ? "Lance" : null
  },
  // Lethal hits
  (description) => {
    const regex = /^Critical hit : always wound( \(if attacker\))?$/
    return description.match(regex) ? "Lethal hits" : null
  },
  // Melta
  (description) => {
    const regex = new RegExp(
      `^\\+(${regexDiceNotation}) to damage \\(within half range\\)$`,
      "i"
    )
    const match = description.match(regex)
    return match ? `Melta ${match[1]}` : null
  },
  // Rapid fire
  (description) => {
    const regex = new RegExp(
      `^Increase attacks by (${regexDiceNotation}) \\(within half range\\)$`,
      "i"
    )
    const match = description.match(regex)
    return match ? `Rapid fire ${match[1]}` : null
  },
  // Stealth
  (description) => {
    const regex = /^-1 to hit roll \(ranged only\)( \(if defender\))?$/
    return description.match(regex) ? "Stealth" : null
  },
  // Sustained hits
  (description) => {
    // Technically this regex pattern will also match nonsense like
    // "Critical hit : 1D3 extra hits" (note the "1D3")
    // but we don't need to worry as the modifier description
    // shouldn't output that kind of nonsense in the first place.
    const regex = new RegExp(
      `^Critical hit : (${regexDiceNotation}) extra hit(s?)( \\(if attacker\\))?$`,
      "i"
    )
    const match = description.match(regex)
    return match ? `Sustained hits ${match[1]}` : null
  },
  // Torrent
  (description) => {
    const regex = /^Always hit$/
    return description.match(regex) ? "Torrent" : null
  },
  // Twin-linked
  (description) => {
    const regex = /^Re-roll all possible failed wound rolls$/
    return description.match(regex) ? "Twin-linked" : null
  },
]
