// Composables
import { createRouter, createWebHistory } from "vue-router"

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/OneVsOne.vue"),
      },
    ],
  },
  {
    path: "/manage-profiles",
    name: "ManageProfiles",
    component: () => import("@/views/ManageProfiles.vue"),
  },
  {
    path: "/changelog",
    name: "Changelog",
    component: () => import("@/views/Changelog.vue"),
  },
  {
    path: "/faq",
    name: "Faq",
    component: () => import("@/views/Faq.vue"),
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: () => import("@/views/Feedback.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/views/About.vue"),
  },
  {
    path: "/mathhammer",
    name: "MathHammer",
    component: () => import("@/views/MathHammer.vue"),
  },
  {
    path: "/send-debug-data",
    name: "SendDebugData",
    component: () => import("@/views/SendDebugData.vue"),
  },
  {
    path: "/support-unitcrunch",
    name: "SupportUnitCrunch",
    component: () => import("@/views/SupportUnitCrunch.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("@/views/PrivacyPolicy.vue"),
  },
  {
    path: "/dev/modifier-sandbox",
    name: "ModifierSandbox",
    component: () => import("@/views/dev/ModifierSandbox.vue"),
  },
  {
    path: "/dev/migrate-preset-modifiers",
    name: "DevMigratePresetModifiers",
    component: () => import("@/views/dev/MigratePresetModifiers.vue"),
  },
  {
    path: "/dev/migrate-preset-profiles",
    name: "DevMigratePresetProfiles",
    component: () => import("@/views/dev/MigratePresetProfiles.vue"),
  },
  // Redirect specific legacy paths to somewhere other than "Home".
  {
    path: "/known-issues",
    redirect: { name: "Feedback" },
  },
  // Redirect all other paths to "Home".
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    redirect: { name: "Home" },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  // Prevent "/dev/**/*" paths from being accessed if env is not dev.
  if (import.meta.env.VITE_APP_ENVIRONMENT === "dev") {
    // Proceed as normal.
    next()
  } else {
    if (to.path.startsWith("/dev/")) {
      // Redirect to home.
      next("/")
    } else {
      // Proceed as normal.
      next()
    }
  }
})

export default router
