import migrateWeaponAbility from "@/lib/update/migrations/0.46.0/migrateWeaponAbility"
export default function migrate_0_46_0(profile) {
  // Profile weapons.
  if (profile.weapons?.length) {
    profile.weapons.forEach((weapon, weaponIndex) => {
      // Weapon abilities.
      if (profile.weapons[weaponIndex].abilities?.length) {
        profile.weapons[weaponIndex].abilities.forEach((ability) => {
          migrateWeaponAbility(ability, profile)
          profile.updateStatus.updated = true
        })
      }
    })
  }

  // Return migrated profile.
  return profile
}
