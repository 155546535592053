<script setup>
import AppBar from "@/components/App/AppBar.vue"
import NavDrawer from "@/components/App/NavDrawer.vue"
import { computed, defineAsyncComponent } from "vue"
import { localStorageEnabled } from "@/lib/crud/localStorage"
import { useGlobalStore } from "@/stores/global"
import { useRouter } from "vue-router"

const DialogLocalStorage = defineAsyncComponent(
  () => import("@/components/App/DialogLocalStorage.vue")
)
const Init = defineAsyncComponent(() => import("@/components/Init/Init.vue"))
const IntroMessage = defineAsyncComponent(
  () => import("@/components/App/IntroMessage.vue")
)

const store = useGlobalStore()
const router = useRouter()

// Check if we're going to be running automated tests.
if (import.meta.env.VITE_APP_SEED_TEST_DATA === "true") {
  // Bypass the intro message.
  store.newUser = false
}

const showIntroMessage = computed(() => {
  if (store.updateInProgress) {
    return false
  }
  if (store.newUser) {
    // TODO: Indicate whether intro message should be shown on route meta rather than checking route names.
    if (
      router.currentRoute.value.name === "Home" ||
      router.currentRoute.value.name === "ManageProfiles"
    ) {
      return true
    }
  }
  return false
})
</script>

<template>
  <v-app>
    <AppBar />
    <NavDrawer />
    <v-main class="px-4 my-4">
      <IntroMessage v-if="showIntroMessage" />
      <DialogLocalStorage v-else-if="!localStorageEnabled()" />
      <template v-else>
        <Init />
        <router-view />
      </template>
    </v-main>
  </v-app>
</template>
