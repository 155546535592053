import updateWeaponAbility from "@/lib/update/migrations/0.31.0/updateWeaponAbility"
export default function migrate_0_31_0(profile) {
  // Profile weapons.
  if (profile.weapons?.length) {
    profile.weapons.forEach((weapon, weaponIndex) => {
      // Weapon abilities.
      if (profile.weapons[weaponIndex].abilities?.length) {
        profile.weapons[weaponIndex].abilities.forEach((ability) => {
          updateWeaponAbility(ability, profile)
        })
      }
    })
  }

  // Return migrated profile.
  return profile
}
